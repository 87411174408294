/**
 * /* <link href='http://fonts.googleapis.com/css?family=Open+Sans+Condensed:300' rel='stylesheet' type='text/css'>
 *
 * @format
 */

.top-head-login-form {
	padding: 0;
	background: none;
	box-shadow: none;
	margin: 0;
}

.form-group{
	/* border: 1px solid #ccc; */
}

.form-group label{
	float: left;
	font-weight: bold;
}

form {
	font-family: "Open Sans Condensed", arial, sans;
	/* min-width: 250px; */
	padding: 20px;
	background: #ffffff;
	margin: 20px auto;

	border-radius: 10px;
	box-shadow: 0 0.4px 0.4px #8080801c, 0 1px 1px #80808028,
		0 2.1px 2.1px #80808032, 0 4.4px 4.4px #8080803d, 0 12px 12px #80808059;
}

input[type="text"],
input[type="date"],
input[type="datetime"],
input[type="email"],
input[type="number"],
input[type="search"],
input[type="time"],
input[type="url"],
input[type="password"],
input
/* input[type="radio"] */
/* input:not(.btn), */
/* .home-form input, */
textarea,
select {
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	outline: none;
	display: block;
	width: 100%;
	padding: 7px;
	border: none;
	border-bottom: 1px solid #dddddd;
	background: white;
	margin-bottom: 10px;
	font: 16px Arial, Helvetica, sans-serif;
	height: 45px;
}

input[type="radio"]{
	/* float:left; */
	/* display: inline; */
}

.form-label{
	display: block;
	font-weight: bold;
	font-size: 1.9em;
}
textarea {
	overflow: hidden;
	min-height: 100px;
	resize: none;
}
/* input[type="button"],
input[type="submit"] {
	/* background: linear-gradient(190deg, #052142, #3cdd0ac2, #052142); *
	border: 1px solid #052142;
	display: inline-block;
	cursor: pointer;
	color: #000;
	font-family: "Open Sans Condensed", sans-serif;
	font-size: 14px;
	padding: 10px 22px;
	text-decoration: none;
	text-transform: uppercase;
	border-radius: 0px;
} */
/* Top Header Login form */
.top-head-login-form input {
	height: 30px;
	max-width: 130px;
	display: inline-block;
	margin-right: 5px;
}

.top-head-login-form button {
	background: #ffffff;
	border-radius: 50px;
	height: 30px;
	padding: 2px;
}

.error {
	color: red;
	font-size: 12px;
}

/* Button */
input[type="button"]:hover,
input[type="submit"]:hover, .btn:hover {
	/* background: linear-gradient(to bottom, #34caca 5%, #30c9c9 100%); */
	background: linear-gradient(45deg, #052142, #3cdd0ac2, #052142);
	background-color: #052142;
	color: #ffffff;
	border: none;
}

.btn{
	border: 1px solid rebeccapurple;
	padding: 10px;
}

.btn-outline-primary {
	border: 1px solid rebeccapurple;
	padding: 5px;
	color: #e95420;
}

.btn-outline-primary:hover {
	border: 1px solid #3cdd0ac2;
	background: none;
	color: #000;
}	

.btn-outline-success, .btn-outline-danger{
	padding: 10px;
}

.btn-success {
	background: green;
}

.btn-danger{
	/* background: red; */
}
.btn-danger, .btn-success, .btn-primary{
	color:#fff;
	/* max-width: 250px; */
	padding: 10px 22px;
	margin-right: 5px;

}

.total-cost-hld{
	padding: 1em;
	background: yellow;
	color: #000;
	font-weight: bold;
	font-size: 2em;
}
