/** @format */

body {
	/* background: linear-gradient(90deg, #850c62, #f80759); */
	background: linear-gradient(90deg, #052142, #3cdd0ac2, #052142);
	color: #fff;
	font-family: "Aerial", sans-serif;
	font-size: 1.1em;
	/* font-family: 'Proxima', Montserrat; */
	/* font-family: 'source_sans_proregular'; */
}

.home-header h1{
	font-size: 3.5em;
}
.home-header p{
	/* color: #201d1d; */
	font-size: 1.5em;
}
a, .nav-link {
	color: #fff;
	text-decoration: none;
}
nav a, footer a, .nav-link
{
	color: yellow;
	text-decoration: none;
}

a:hover {
	color: #fff;
}

.logo{
	color: #fff;
	text-decoration: none;
}

/* Heading */
h2 {
	font-weight: 900;
}

/* Navigation Menu */
ul {
	list-style: none;
	padding: 0;
}
.horizontal li {
	margin-bottom: 0.25em;
	display: inline-block;
	padding-right: 10px;
}

.horiz-menu {
	list-style: none;
}

.horiz-menu li {
	padding: 5px;
	display: inline-block;
}

.feature {
	font-weight: 800;
	padding-right: 5px;
}

.fa {
	padding-right: 15px;
	padding-bottom: 15px;
}

.fa-check-circle {
	color: yellow;
}


/* Table */
.table,
.table {
	color: #fff;
}

/* Card Style */
.card,
.card-body,
.card .table,
.card {
	color: #000;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 
	Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
	line-height: 2rem;
	font-size: 1.2 rem;
}

.card h1, .card h2, .card h3, .card h4, .card h5{
	color: #e95420;
}
.card strong {
	/* color: #060; */
	color: #c04d23;
}

.card p{
	font-size: 1.2 em;
}

.card a:not(.btn),
.card-link {
	/* color: #052142; */
	color: #e95420;
}
.card .btn-primary{
	color: #fff;
}
.card a:hover:no-button,.card a:focus,
.card-link:hover {
	color: #3cdd0ac2;
	color: #060;
	/* opacity: 0.7; */
}

label{
	color: #060;
	font-weight: bold;
	padding-right: 0.5rem;
}

.loading-spinner{
	text-align: center;
	margin-top: 2em;
	vertical-align: middle;
}